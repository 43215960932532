import React from 'react'

const Input = (props) => {
  return (
    <div className="form-group">
      <input
        type="text"
        id={props.name}
        name={props.name}
        type={props.type}
        value={props.value}
        onChange={props.handleChange}
        placeholder={props.placeholder}
      />
      <label htmlFor={props.name} className={`form-label ${props.value && 'active'}`}>{props.title}</label>
    </div>
  )
}

export default Input;