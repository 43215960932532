import React, { Component } from 'react'
import axios from "axios"
import * as qs from "query-string"

import Input from '../form/input'
import Textarea from '../form/textarea'

export default class Form extends Component {
  constructor(props) {
    super(props)
    this.formRef = React.createRef()
    this.state = {
      feedbackMsg: null,
      firstName: "",
      lastName: "",
      companyName: "",
      email: "",
      message: "",
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <div className="contact-form">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <form className="contact-form__form form" ref={this.formRef} name="Contact Form" method="POST" data-netlify-honeypot="bot-field" data-netlify="true" action="#"  onSubmit={event => this.handleSubmit(event)}>
                <input ref="form-name" type="hidden" name="form-name" value="Contact Form" />
                <input ref="bot-field" type="hidden" name="bot-field" />
                <div className="form__row">
                  <Input
                    title={'First Name'}
                    name={'firstName'}
                    type={'text'}

                    value={this.state.firstName}
                    handleChange={(e) => this.handleChange(e, "firstName")}
                  />
                  <Input
                    title={'Last Name'}
                    name={'lastName'}
                    type={'text'}

                    value={this.state.lastName}
                    handleChange={(e) => this.handleChange(e, "lastName")}
                  />
                </div>
                <div className="form__row">
                  <Input
                    title={'Company Name'}
                    name={'companyName'}
                    type={'text'}
                    value={this.state.companyName}
                    handleChange={(e) => this.handleChange(e, "companyName")}
                  />
                  <Input
                    title={'Email'}
                    name={'email'}
                    type={'email'}
                    value={this.state.email}
                    handleChange={(e) => this.handleChange(e, "email")}
                  />
                </div>
                <div className="form__row full-width">
                  <Textarea
                    title={'Your message'}
                    name={'message'}
                    value={this.state.message}
                    handleChange={(e) => this.handleChange(e, "message")}
                  />
                </div>
                {this.state.feedbackMsg && <p class="text-center mb-5" dangerouslySetInnerHTML={{ __html: this.state.feedbackMsg }}></p>}
                <div className="form__row justify-content-center">
                  <button type="submit" className="applied-btn form__submit">Submit</button>
                </div>

              </form>
            </div>
          </div>


        </div>

      </div>
    )
  }

  handleChange(e, field) {
    let value = e.target.value;
    this.setState({
      [field]: value
    });
  }

  handleSubmit(event) {
    const fieldMapper = {
      firstName: "First name",
      lastName: "Last name",
      companyName: "Company name",
      email: "Email",
      message: "Message",
    }
    
    
    
    event.preventDefault()


    let formData = {};
    Object.keys(this.refs).map(key => (formData[key] = this.refs[key].value));
    formData = { ...formData, ...this.state };

    
    delete formData["feedbackMsg"];
    
    const emptyFields = [];

    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        if(key !== "bot-field" && !formData[key]){
          emptyFields.push(fieldMapper[key])
        };
      }
    }

    if (emptyFields.length) {
      let fieldString = emptyFields.map(el=>`<b>${el}</b>`).join(", ");
      this.setState({
        feedbackMsg: `Please fill all the fields. Empty fields: ${fieldString}.` ,
      })
      return;
    }
    
    if (formData["email"].toLowerCase().includes("gmail") || formData["email"].toLowerCase().includes("yahoo")) {
      this.setState({
        feedbackMsg: "Email is invalid, please use a company email address.",
      })
      return;
    }


    const axiosOptions = {
      url: this.formRef.current.getAttribute("action"),
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: qs.stringify(formData),
    }

    // Submit to Netlify. Upon success, set the feedback message and clear all
    // the fields within the form. Upon failure, keep the fields as they are,
    // but set the feedback message to show the error state.
    axios(axiosOptions)
      .then(response => {
        this.setState({
          feedbackMsg: "Form submitted successfully!",
          firstName: "",
          lastName: "",
          companyName: "",
          email: "",
          message: "",
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          feedbackMsg: "Form could not be submitted.",
        })
      }
      )
  }
}