/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Header from "./header"
import 'bootstrap/dist/css/bootstrap.css';
import "../assets/css/layout.css"
import "../assets/css/style.css"
import "../assets/scss/agencies.scss"
import Form from "./agencies/Form";



class Layout extends React.Component {

	render() {
			const { className } = this.props;
			return (
					<div className={className}>
						<Header langs={this.langsMenu}/>
						<main>{this.props.children}</main>
						<div hidden><Form /></div>
					</div>
			)

	}
}


export default Layout