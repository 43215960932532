import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import 'bootstrap/dist/css/bootstrap.css';
import light from '../assets/images/light.svg';
import dark from '../assets/images/dark.svg';
import AALogo from '../assets/images/aa-logo.svg';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';


class Header extends React.Component {

	componentDidMount() {
		if (localStorage.getItem('theme')) {
			document.body.classList.add('dark');
		}
		this.changeBgfromHeight();
	}






	changeBgfromHeight = () => {
		if (document.body.clientHeight < 1800) {
			document.querySelector('.round1').classList.add('smallHeight');
			document.querySelector('.round2').classList.add('smallHeight');
			document.body.classList.add('smallHeight');
		} else {
			document.querySelector('.round1').classList.remove('smallHeight');
			document.querySelector('.round2').classList.remove('smallHeight');
			document.body.classList.remove('smallHeight');
		}
	};

	handleClick = (e) => {
		e.preventDefault();
		if (document.body.classList.contains('dark')) {
			document.body.classList.remove('dark');
			localStorage.removeItem('theme');
		} else {
			document.body.classList.add('dark');
			localStorage.setItem('theme', 'dark');
		}
	};


	render() {
		const logoLink = <Link to={"/"} className="logo"><SVG src={AALogo} /></Link> 
			
		return (
			<header className="header">
				<div className="container-fluid width1280">
					<div className="row align-items-center justify-content-between">
						<div className="col">
							{logoLink}
						</div>

						<div className="col text-right d-flex align-items-center justify-content-end">
							<div className="switcher">
								<a href="/" onClick={this.handleClick}><img src={dark} className="darkIcon" alt="" /><img src={light} className="lightIcon" alt="" /></a>
							</div>
						</div>
					</div>
				</div>
			</header>
		)
	}
}

Header.propTypes = {
	siteTitle: PropTypes.string,
	isAgency: PropTypes.bool,
}

Header.defaultProps = {
	siteTitle: ``,
	isAgency: false,
}

export default Header
